/* tslint:disable */
/* eslint-disable */
/**
 * Ster.nl API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SterWebsiteModelPortalSalesPeriod
 */
export interface SterWebsiteModelPortalSalesPeriod {
  /**
   *
   * @type {Date}
   * @memberof SterWebsiteModelPortalSalesPeriod
   */
  salesPeriodStartDate?: Date;
  /**
   *
   * @type {Date}
   * @memberof SterWebsiteModelPortalSalesPeriod
   */
  salesPeriodEndDate?: Date;
}

export function SterWebsiteModelPortalSalesPeriodFromJSON(
  json: any,
): SterWebsiteModelPortalSalesPeriod {
  return SterWebsiteModelPortalSalesPeriodFromJSONTyped(json, false);
}

export function SterWebsiteModelPortalSalesPeriodFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SterWebsiteModelPortalSalesPeriod {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    salesPeriodStartDate: !exists(json, "salesPeriodStartDate")
      ? undefined
      : new Date(json["salesPeriodStartDate"]),
    salesPeriodEndDate: !exists(json, "salesPeriodEndDate")
      ? undefined
      : new Date(json["salesPeriodEndDate"]),
  };
}

export function SterWebsiteModelPortalSalesPeriodToJSON(
  value?: SterWebsiteModelPortalSalesPeriod | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    salesPeriodStartDate:
      value.salesPeriodStartDate === undefined
        ? undefined
        : value.salesPeriodStartDate.toISOString(),
    salesPeriodEndDate:
      value.salesPeriodEndDate === undefined
        ? undefined
        : value.salesPeriodEndDate.toISOString(),
  };
}
