/* tslint:disable */
/* eslint-disable */
/**
 * Ster.nl API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  SterWebsiteModelPortalCalculateInput,
  SterWebsiteModelPortalCalculateInputFromJSON,
  SterWebsiteModelPortalCalculateInputToJSON,
  SterWebsiteModelPortalCalculationResult,
  SterWebsiteModelPortalCalculationResultFromJSON,
  SterWebsiteModelPortalCalculationResultToJSON,
  SterWebsiteModelPortalForecastConversionGroups,
  SterWebsiteModelPortalForecastConversionGroupsFromJSON,
  SterWebsiteModelPortalForecastConversionGroupsToJSON,
  SterWebsiteModelPortalForecastRequest,
  SterWebsiteModelPortalForecastRequestFromJSON,
  SterWebsiteModelPortalForecastRequestToJSON,
  SterWebsiteModelPortalForecastResult,
  SterWebsiteModelPortalForecastResultFromJSON,
  SterWebsiteModelPortalForecastResultToJSON,
  SterWebsiteModelPortalPackageOutput,
  SterWebsiteModelPortalPackageOutputFromJSON,
  SterWebsiteModelPortalPackageOutputToJSON,
  SterWebsiteModelPortalProposalRequestLite,
  SterWebsiteModelPortalProposalRequestLiteFromJSON,
  SterWebsiteModelPortalProposalRequestLiteToJSON,
  SterWebsiteModelPortalSalesPeriodResult,
  SterWebsiteModelPortalSalesPeriodResultFromJSON,
  SterWebsiteModelPortalSalesPeriodResultToJSON,
} from "../models";

export interface PortalCalculateRequest {
  medium: string;
  calculateInput: SterWebsiteModelPortalCalculateInput;
}

export interface PortalGenerateProposalRequest {
  proposalRequest: SterWebsiteModelPortalProposalRequestLite;
}

export interface PortalGetForecastRequest {
  medium: string;
  forecastRequest: SterWebsiteModelPortalForecastRequest;
}

export interface PortalGetPackagesRequest {
  medium: string;
  year: number;
  monthNumber: number;
}

export interface PortalGetSpotLengthsRequest {
  medium: string;
  year: number;
}

/**
 *
 */
export class PortalApi extends runtime.BaseAPI {
  /**
   */
  async portalCalculateRaw(
    requestParameters: PortalCalculateRequest,
  ): Promise<runtime.ApiResponse<SterWebsiteModelPortalCalculationResult>> {
    if (
      requestParameters.medium === null ||
      requestParameters.medium === undefined
    ) {
      throw new runtime.RequiredError(
        "medium",
        "Required parameter requestParameters.medium was null or undefined when calling portalCalculate.",
      );
    }

    if (
      requestParameters.calculateInput === null ||
      requestParameters.calculateInput === undefined
    ) {
      throw new runtime.RequiredError(
        "calculateInput",
        "Required parameter requestParameters.calculateInput was null or undefined when calling portalCalculate.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.medium !== undefined) {
      queryParameters["medium"] = requestParameters.medium;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/api/portal/calculate`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: SterWebsiteModelPortalCalculateInputToJSON(
        requestParameters.calculateInput,
      ),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SterWebsiteModelPortalCalculationResultFromJSON(jsonValue),
    );
  }

  /**
   */
  async portalCalculate(
    requestParameters: PortalCalculateRequest,
  ): Promise<SterWebsiteModelPortalCalculationResult> {
    const response = await this.portalCalculateRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async portalGenerateProposalRaw(
    requestParameters: PortalGenerateProposalRequest,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.proposalRequest === null ||
      requestParameters.proposalRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "proposalRequest",
        "Required parameter requestParameters.proposalRequest was null or undefined when calling portalGenerateProposal.",
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/api/portal/generateProposal`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: SterWebsiteModelPortalProposalRequestLiteToJSON(
        requestParameters.proposalRequest,
      ),
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async portalGenerateProposal(
    requestParameters: PortalGenerateProposalRequest,
  ): Promise<void> {
    await this.portalGenerateProposalRaw(requestParameters);
  }

  /**
   */
  async portalGetForecastRaw(
    requestParameters: PortalGetForecastRequest,
  ): Promise<runtime.ApiResponse<SterWebsiteModelPortalForecastResult>> {
    if (
      requestParameters.medium === null ||
      requestParameters.medium === undefined
    ) {
      throw new runtime.RequiredError(
        "medium",
        "Required parameter requestParameters.medium was null or undefined when calling portalGetForecast.",
      );
    }

    if (
      requestParameters.forecastRequest === null ||
      requestParameters.forecastRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "forecastRequest",
        "Required parameter requestParameters.forecastRequest was null or undefined when calling portalGetForecast.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.medium !== undefined) {
      queryParameters["medium"] = requestParameters.medium;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/api/portal/forecast`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: SterWebsiteModelPortalForecastRequestToJSON(
        requestParameters.forecastRequest,
      ),
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SterWebsiteModelPortalForecastResultFromJSON(jsonValue),
    );
  }

  /**
   */
  async portalGetForecast(
    requestParameters: PortalGetForecastRequest,
  ): Promise<SterWebsiteModelPortalForecastResult> {
    const response = await this.portalGetForecastRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async portalGetForecastConversionGroupsRaw(): Promise<
    runtime.ApiResponse<SterWebsiteModelPortalForecastConversionGroups>
  > {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/api/portal/forecastConversionGroups`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SterWebsiteModelPortalForecastConversionGroupsFromJSON(jsonValue),
    );
  }

  /**
   */
  async portalGetForecastConversionGroups(): Promise<SterWebsiteModelPortalForecastConversionGroups> {
    const response = await this.portalGetForecastConversionGroupsRaw();
    return await response.value();
  }

  /**
   */
  async portalGetPackagesRaw(
    requestParameters: PortalGetPackagesRequest,
  ): Promise<runtime.ApiResponse<SterWebsiteModelPortalPackageOutput>> {
    if (
      requestParameters.medium === null ||
      requestParameters.medium === undefined
    ) {
      throw new runtime.RequiredError(
        "medium",
        "Required parameter requestParameters.medium was null or undefined when calling portalGetPackages.",
      );
    }

    if (
      requestParameters.year === null ||
      requestParameters.year === undefined
    ) {
      throw new runtime.RequiredError(
        "year",
        "Required parameter requestParameters.year was null or undefined when calling portalGetPackages.",
      );
    }

    if (
      requestParameters.monthNumber === null ||
      requestParameters.monthNumber === undefined
    ) {
      throw new runtime.RequiredError(
        "monthNumber",
        "Required parameter requestParameters.monthNumber was null or undefined when calling portalGetPackages.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.medium !== undefined) {
      queryParameters["medium"] = requestParameters.medium;
    }

    if (requestParameters.year !== undefined) {
      queryParameters["year"] = requestParameters.year;
    }

    if (requestParameters.monthNumber !== undefined) {
      queryParameters["monthNumber"] = requestParameters.monthNumber;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/api/portal/packages`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SterWebsiteModelPortalPackageOutputFromJSON(jsonValue),
    );
  }

  /**
   */
  async portalGetPackages(
    requestParameters: PortalGetPackagesRequest,
  ): Promise<SterWebsiteModelPortalPackageOutput> {
    const response = await this.portalGetPackagesRaw(requestParameters);
    return await response.value();
  }

  /**
   */
  async portalGetSalesPeriodRaw(): Promise<
    runtime.ApiResponse<Array<SterWebsiteModelPortalSalesPeriodResult>>
  > {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/api/portal/salesPeriod`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      jsonValue.map(SterWebsiteModelPortalSalesPeriodResultFromJSON),
    );
  }

  /**
   */
  async portalGetSalesPeriod(): Promise<
    Array<SterWebsiteModelPortalSalesPeriodResult>
  > {
    const response = await this.portalGetSalesPeriodRaw();
    return await response.value();
  }

  /**
   */
  async portalGetSpotLengthsRaw(
    requestParameters: PortalGetSpotLengthsRequest,
  ): Promise<runtime.ApiResponse<Array<number>>> {
    if (
      requestParameters.medium === null ||
      requestParameters.medium === undefined
    ) {
      throw new runtime.RequiredError(
        "medium",
        "Required parameter requestParameters.medium was null or undefined when calling portalGetSpotLengths.",
      );
    }

    if (
      requestParameters.year === null ||
      requestParameters.year === undefined
    ) {
      throw new runtime.RequiredError(
        "year",
        "Required parameter requestParameters.year was null or undefined when calling portalGetSpotLengths.",
      );
    }

    const queryParameters: any = {};

    if (requestParameters.medium !== undefined) {
      queryParameters["medium"] = requestParameters.medium;
    }

    if (requestParameters.year !== undefined) {
      queryParameters["year"] = requestParameters.year;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/api/portal/spotlengths`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   */
  async portalGetSpotLengths(
    requestParameters: PortalGetSpotLengthsRequest,
  ): Promise<Array<number>> {
    const response = await this.portalGetSpotLengthsRaw(requestParameters);
    return await response.value();
  }
}
