import classNames from "classnames";
import { memo } from "react";

import styles from "./SimpleSpinner.module.less";

interface SimpleSpinnerProps {
  className?: string;
  invertColor?: boolean;
}

const SimpleSpinner = memo(({ className, invertColor }: SimpleSpinnerProps) => (
  <div
    className={classNames(styles.simpleSpinner, className, {
      [styles.inverted]: invertColor,
    })}
  >
    <div className={styles.ldsEllipsis}>
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
));

export default SimpleSpinner;
