import { Icons, Tooltip } from "@ster/ster-toolkit";
import { memo, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { useDebounce } from "use-debounce";

import { SterWebsiteModelPortalCalculateInput } from "../../api";
import { calculateState } from "../../atoms";
import { Medium } from "../../types";

export interface GrpsInputValue {
  grp?: number;
  spotsPerDayChannel?: number;
}

const formatNumber = (value?: number) =>
  Intl.NumberFormat("nl-NL", {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  }).format(value ?? 0);

const GrpsInput = ({
  medium,
  calculateInput,
  value,
  onChange,
}: {
  medium: Medium;
  calculateInput: SterWebsiteModelPortalCalculateInput;
  value?: GrpsInputValue;
  onChange?: (newValue: GrpsInputValue) => void;
}): JSX.Element => {
  const [debouncedBudget] = useDebounce(calculateInput.budget ?? 0, 500);
  const newValue = useRecoilValue(
    calculateState({
      medium,
      calculateInput: {
        ...calculateInput,
        budget: Number(debouncedBudget),
        calculated: undefined,
      },
    }),
  );

  useEffect(() => {
    if (
      onChange &&
      (value?.grp !== newValue?.grp ||
        value?.spotsPerDayChannel !== newValue?.spotsPerDayChannel)
    ) {
      onChange(newValue);
    }
  }, [newValue, onChange, value]);

  return (
    <>
      <>
        GRP&apos;s{" "}
        <Tooltip title="Gross Rating Point (GRP) is een eenheid die wordt gebruikt om het bereik van een reclamecampagne uit te drukken. 1 GRP = 1% bereik in de doelgroep.">
          <span>
            <Icons.QuestionIcon width={16} height={16} />
          </span>
        </Tooltip>
        : {formatNumber(value?.grp)}
      </>
      {value?.spotsPerDayChannel && value?.spotsPerDayChannel > 0 && (
        <>
          <br />
          {`Spot(s) per dag${
            (calculateInput.channels?.length ?? 0) > 1 ? " per zender" : ""
          }`}{" "}
          <Tooltip title="Dit is een indicatie.">
            <span>
              <Icons.QuestionIcon width={16} height={16} />
            </span>
          </Tooltip>
          : {formatNumber(value.spotsPerDayChannel)}
        </>
      )}
    </>
  );
};

export default memo(GrpsInput);
