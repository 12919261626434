/* tslint:disable */
/* eslint-disable */
/**
 * Ster.nl API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SterWebsiteModelPortalPackageSimple
 */
export interface SterWebsiteModelPortalPackageSimple {
  /**
   *
   * @type {number}
   * @memberof SterWebsiteModelPortalPackageSimple
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof SterWebsiteModelPortalPackageSimple
   */
  code: string;
  /**
   *
   * @type {Array<string>}
   * @memberof SterWebsiteModelPortalPackageSimple
   */
  channels: Array<string>;
  /**
   *
   * @type {string}
   * @memberof SterWebsiteModelPortalPackageSimple
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof SterWebsiteModelPortalPackageSimple
   */
  targetGroupId: string;
}

export function SterWebsiteModelPortalPackageSimpleFromJSON(
  json: any,
): SterWebsiteModelPortalPackageSimple {
  return SterWebsiteModelPortalPackageSimpleFromJSONTyped(json, false);
}

export function SterWebsiteModelPortalPackageSimpleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SterWebsiteModelPortalPackageSimple {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    code: json["code"],
    channels: json["channels"],
    name: json["name"],
    targetGroupId: json["targetGroupId"],
  };
}

export function SterWebsiteModelPortalPackageSimpleToJSON(
  value?: SterWebsiteModelPortalPackageSimple | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    code: value.code,
    channels: value.channels,
    name: value.name,
    targetGroupId: value.targetGroupId,
  };
}
