import { Button, Typography } from "@ster/ster-toolkit";
import { Form } from "antd";
import { SelectValue } from "antd/lib/select";
import { memo, useCallback, useEffect, useMemo, useRef } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import {
  appSettingsState,
  lastIdState,
  orderState,
  packagesState,
  spotLengthsState,
} from "../../atoms";
import { flattenOrder } from "../../chatwoot";
import { Order, SubOrder } from "../../types";
import { packagesSortOrder } from "../../utils";
import ContactForm from "./ContactForm";
import Forecast from "./Forecast";
import styles from "./OrderForm.module.less";
import PackageAdd from "./PackageAdd";
import SubOrderForm from "./SubOrderForm";

const OrderForm = ({ order }: { order: Order }) => {
  useEffect(() => {
    if (!order || window.$chatwoot?.hasLoaded !== true) {
      return;
    }

    window.$chatwoot?.setCustomAttributes({
      campagne_calculator_aanvraag: JSON.stringify(flattenOrder(order)),
    });
  }, [order]);

  const setOrder = useSetRecoilState(orderState);
  const [lastId, setLastId] = useRecoilState(lastIdState);
  const forecastRef = useRef<HTMLInputElement>(null);

  const spotLengths = useRecoilValue(
    spotLengthsState({
      medium: order.medium,
      year: order.period[0].getFullYear(),
    }),
  );

  const packageRequest = {
    medium: order.medium,
    monthNumber: order.period[0].getMonth() + 1,
    year: order.period[0].getFullYear(),
  };

  const { packages } = useRecoilValue(packagesState(packageRequest));

  const packagesSorted = useMemo(
    () =>
      packages
        .slice()
        .sort(
          (a, b) =>
            packagesSortOrder.indexOf(a.name) -
            packagesSortOrder.indexOf(b.name),
        ),
    [packages],
  );

  const [form] = Form.useForm();

  useEffect(() => {
    const currentlySelected = form.getFieldValue("packageCode");
    if (
      !currentlySelected &&
      packages &&
      packages[0].targetGroups[0].packageCode
    ) {
      // set initial packageCode for new suborders
      form.setFieldsValue({
        packageCode: packages[0].targetGroups[0].packageCode,
      });
    }
  }, [form, packages]);

  const handleAddSubOrder = useCallback(
    (id: SelectValue) => {
      const newId = lastId + 1;
      const selectedPackage = packages.find((s) => s.id === id);
      setLastId(newId);
      setOrder({
        ...order,
        subOrders: [
          ...order.subOrders,
          {
            id: newId,
            period: order.period,
            packageCode: selectedPackage?.targetGroups[0].packageCode,
            packageName: selectedPackage?.name,
            targetGroupId: selectedPackage?.targetGroups[0].targetGroupId,
            channels: selectedPackage?.targetGroups[0].channels,
            spotLength: order.medium === "tv" ? 30 : 20,
            targetGroupIndex:
              selectedPackage?.targetGroups.length === 1
                ? selectedPackage?.targetGroups[0].index
                : undefined,
          },
        ],
      });
    },
    [lastId, order, packages, setLastId, setOrder],
  );

  const handleDeleteSubOrder = useCallback(
    (id: number) => {
      const newSubOrders = order.subOrders?.filter((s) => s.id !== id) ?? [];
      setOrder({
        ...order,
        subOrders: [...newSubOrders],
      });
    },
    [order, setOrder],
  );

  const handleUpdateSubOrder = useCallback(
    (subOrder: SubOrder) => {
      const index = order.subOrders.map(({ id }) => id).indexOf(subOrder.id);
      const newSubOrders = [
        ...order.subOrders.slice(0, index),
        { ...order.subOrders[index], ...subOrder },
        ...order.subOrders.slice(index + 1),
      ];

      setOrder({
        ...order,
        subOrders: newSubOrders,
      });
    },
    [order, setOrder],
  );

  const handleAddForecastConversionGroup = useCallback(
    (value: string) => {
      setOrder({
        ...order,
        forecastConversionGroups: [...order.forecastConversionGroups, value],
      });
    },
    [order, setOrder],
  );

  const handleDeleteForecastConversionGroup = useCallback(
    (value: string) => {
      setOrder({
        ...order,
        forecastConversionGroups: order.forecastConversionGroups.filter(
          (s) => s !== value,
        ),
      });
    },
    [order, setOrder],
  );

  useEffect(() => {
    forecastRef.current?.scrollIntoView({ block: "center" });
  }, []);

  const { homeUrl } = useRecoilValue(appSettingsState);
  const handleReset = useCallback(() => {
    window.location.href = homeUrl;
  }, [homeUrl]);

  if (!packages || packages.length === 0) {
    return null;
  }

  return (
    <>
      <div className={styles.buttonBar}>
        <Typography.Title level={2}>Pakketten</Typography.Title>
        <Button mode="tertiary" onClick={handleReset}>
          Opnieuw beginnen
        </Button>
      </div>

      {order.subOrders.map((subOrder) => (
        <SubOrderForm
          key={subOrder.id}
          medium={order.medium}
          subOrder={subOrder}
          period={subOrder.period}
          onDeleteClick={handleDeleteSubOrder}
          onUpdateSubOrder={handleUpdateSubOrder}
          packages={packagesSorted}
          spotLengths={spotLengths}
        />
      ))}

      <PackageAdd
        form={form}
        packages={packagesSorted}
        onAdd={handleAddSubOrder}
      />

      <div ref={forecastRef}>
        <Forecast
          medium={order.medium}
          order={order}
          onDeleteConversionGroup={handleDeleteForecastConversionGroup}
          onAddConversionGroup={handleAddForecastConversionGroup}
        />
      </div>

      <ContactForm order={order} />
    </>
  );
};

export default memo(OrderForm);
