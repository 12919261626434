import { Icons, Option, Select, Tooltip } from "@ster/ster-toolkit";
import { Select as SelectAntD } from "antd";
import { SelectValue } from "antd/lib/select";
import { memo, useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import { differenceInDays } from "date-fns";
import { SterWebsiteModelPortalPackageSimple } from "../../api";
import { packagesState } from "../../atoms";
import { Medium, PackageData } from "../../types";
import { getChannelName, getTargetGroupName } from "../../utils";
import getChannelImage from "../channelImages";
import styles from "./ChannelSelect.module.less";

const ChannelSelect = ({
  medium,
  period,
  value,
  onChange,
}: {
  medium: Medium;
  period: [Date, Date];
  value?: PackageData;
  onChange?: (v: PackageData) => void;
}) => {
  const [selectedPackages, setSelectedPackages] =
    useState<SterWebsiteModelPortalPackageSimple[]>();

  const { packages, simpleGroup: packagesSimple } = useRecoilValue(
    packagesState({
      medium,
      monthNumber: period[0].getMonth() + 1,
      year: period[0].getFullYear(),
    }),
  );

  useEffect(() => {
    if (differenceInDays(period[1], period[0]) >= 6) {
      setSelectedPackages(
        packagesSimple.find((s) => s.minDays === 7)?.packages,
      );
    } else if (differenceInDays(period[1], period[0]) >= 4) {
      setSelectedPackages(
        packagesSimple.find((s) => s.minDays === 5)?.packages,
      );
    } else {
      setSelectedPackages(
        packagesSimple.find((s) => s.minDays === 3)?.packages,
      );
    }

    if (
      selectedPackages &&
      value &&
      !selectedPackages?.some((s) => s.code === value?.packageCode)
    ) {
      onChange?.({
        packageCode: selectedPackages?.[0].code,
        targetGroupIndex: value?.targetGroupIndex,
      });
    }
  }, [onChange, packagesSimple, period, selectedPackages, value]);

  const singleChannels =
    selectedPackages?.filter((s) => s.channels?.length === 1) ?? [];

  const multiChannels = (
    selectedPackages?.filter((s) => s.channels?.length > 1) ?? []
  ).sort((a, b) =>
    // eslint-disable-next-line no-nested-ternary
    a.code === "ALGEMEEN NUT"
      ? 1
      : b.code === "ALGEMEEN NUT"
        ? -1
        : a.targetGroupId.localeCompare(b.targetGroupId),
  );

  const handleChange = useCallback(
    (selectedId: SelectValue) => {
      const selectedSimplePackage = selectedPackages?.find(
        (t) => t.id.toString() === selectedId?.toString(),
      );

      const selectedPackage = packages
        .map(({ targetGroups }) =>
          targetGroups.find(
            ({ packageCode, targetGroupId, channels }) =>
              packageCode === selectedSimplePackage?.code &&
              targetGroupId === selectedSimplePackage?.targetGroupId &&
              channels.join(",") === selectedSimplePackage?.channels?.join(","),
          ),
        )
        .find((s) => s);

      onChange?.({
        packageCode: selectedPackage?.packageCode,
        targetGroupIndex: selectedPackage?.index,
        targetGroupId: selectedPackage?.targetGroupId,
        id: Number(selectedId?.toString()),
      });
    },
    [onChange, packages, selectedPackages],
  );

  return (
    <Select
      value={value?.id}
      onChange={handleChange}
      placeholder="Kies een zender of doelgroep"
      id="channel"
      className="channelSelect"
      listHeight={400}
      popupClassName={styles.popup}
    >
      <SelectAntD.OptGroup label="Zender">
        {singleChannels?.map(({ channels, id }) => (
          <Option key={id} value={id}>
            <div className="channels">
              {channels.map((c) => getChannelImage(c))}
            </div>
            <div>{getChannelName(channels.join(", "))}</div>
          </Option>
        ))}
      </SelectAntD.OptGroup>
      {multiChannels.length > 0 && (
        <SelectAntD.OptGroup label="Combinatiepakket">
          {multiChannels?.map(({ id, targetGroupId, channels }) =>
            getTargetGroupName(targetGroupId) === "Algemeen nut" ? (
              <Option key={id} value={id}>
                {getTargetGroupName(targetGroupId)}
                <Tooltip
                  title={
                    <>
                      Enkel voor charitatieve organisaties met een keurmerk van
                      het Centraal Bureau Fondsenwerving (CBF) of de status van
                      algemeen nut beogende instelling (ANBI-status)
                    </>
                  }
                  color="#fff"
                  overlayClassName={styles.tooltip}
                >
                  <span className="help-text">
                    <Icons.QuestionIcon width={16} height={16} />
                  </span>
                </Tooltip>
              </Option>
            ) : (
              <Option key={id} value={id}>
                {getTargetGroupName(targetGroupId)}
                <div className="channels multi">
                  {channels.map((c) => getChannelImage(c))}
                </div>
              </Option>
            ),
          )}
        </SelectAntD.OptGroup>
      )}{" "}
      *
    </Select>
  );
};

export default memo(ChannelSelect);
