import {
  PortalApi,
  PortalCalculateRequest,
  PortalGenerateProposalRequest,
  PortalGetForecastRequest,
  PortalGetPackagesRequest,
  PortalGetSpotLengthsRequest,
  SterWebsiteModelPortalCalculationResult,
  SterWebsiteModelPortalForecastTargetGroup,
  SterWebsiteModelPortalPackageOutput,
  SterWebsiteModelPortalSalesPeriodResult,
} from "./api";
import { SterWebsiteModelPortalForecastResult } from "./api/models";
import apiConfig from "./apiConfig";
import { Medium } from "./types";

export interface PackagesRequest {
  medium: Medium;
  month: number;
  year: number;
}

export const fetchPackages = async (
  request: PortalGetPackagesRequest,
): Promise<SterWebsiteModelPortalPackageOutput> => {
  const portalApi = new PortalApi(apiConfig());
  return Promise.resolve(portalApi.portalGetPackages(request));
};

export interface ForecastsRequest {
  medium: Medium;
}

export const fetchSpotLengths = async (
  request: PortalGetSpotLengthsRequest,
): Promise<Array<number>> => {
  const portalApi = new PortalApi(apiConfig());
  return Promise.resolve(portalApi.portalGetSpotLengths(request));
};

export const fetchCalculate = async (
  input: PortalCalculateRequest,
): Promise<SterWebsiteModelPortalCalculationResult> => {
  const portalApi = new PortalApi(apiConfig());
  return portalApi.portalCalculate(input);
};

export const fetchForecasts = async (
  input: PortalGetForecastRequest,
): Promise<SterWebsiteModelPortalForecastResult> => {
  const portalApi = new PortalApi(apiConfig());
  return portalApi.portalGetForecast(input);
};

export const fetchProposal = async (
  input: PortalGenerateProposalRequest,
): Promise<void> => {
  const portalApi = new PortalApi(apiConfig());
  return portalApi.portalGenerateProposal(input);
};

export const fetchForecastConversionGroups = async (
  medium: Medium,
): Promise<SterWebsiteModelPortalForecastTargetGroup[]> => {
  const portalApi = new PortalApi(apiConfig());
  return (await Promise.resolve(portalApi.portalGetForecastConversionGroups()))
    .conversionGroups[medium];
};

export const fetchSalesPeriod = async (): Promise<
  SterWebsiteModelPortalSalesPeriodResult[]
> => {
  const portalApi = new PortalApi(apiConfig());
  return portalApi.portalGetSalesPeriod();
};
