/* tslint:disable */
/* eslint-disable */
/**
 * Ster.nl API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  SterWebsiteModelPortalTargetGroupOutput,
  SterWebsiteModelPortalTargetGroupOutputFromJSON,
  SterWebsiteModelPortalTargetGroupOutputFromJSONTyped,
  SterWebsiteModelPortalTargetGroupOutputToJSON,
} from "./";

/**
 *
 * @export
 * @interface SterWebsiteModelPortalPackageExtended
 */
export interface SterWebsiteModelPortalPackageExtended {
  /**
   *
   * @type {number}
   * @memberof SterWebsiteModelPortalPackageExtended
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof SterWebsiteModelPortalPackageExtended
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof SterWebsiteModelPortalPackageExtended
   */
  minDays?: number;
  /**
   *
   * @type {Array<SterWebsiteModelPortalTargetGroupOutput>}
   * @memberof SterWebsiteModelPortalPackageExtended
   */
  targetGroups: Array<SterWebsiteModelPortalTargetGroupOutput>;
}

export function SterWebsiteModelPortalPackageExtendedFromJSON(
  json: any,
): SterWebsiteModelPortalPackageExtended {
  return SterWebsiteModelPortalPackageExtendedFromJSONTyped(json, false);
}

export function SterWebsiteModelPortalPackageExtendedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SterWebsiteModelPortalPackageExtended {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    name: json["name"],
    minDays: !exists(json, "minDays") ? undefined : json["minDays"],
    targetGroups: (json["targetGroups"] as Array<any>).map(
      SterWebsiteModelPortalTargetGroupOutputFromJSON,
    ),
  };
}

export function SterWebsiteModelPortalPackageExtendedToJSON(
  value?: SterWebsiteModelPortalPackageExtended | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    minDays: value.minDays,
    targetGroups: (value.targetGroups as Array<any>).map(
      SterWebsiteModelPortalTargetGroupOutputToJSON,
    ),
  };
}
