import { Button, Container, Form, Input, Typography } from "@ster/ster-toolkit";
import { Alert, Form as AntForm, Row } from "antd";
import { Store } from "antd/lib/form/interface";
import { Suspense, memo, useCallback, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

import { SterWebsiteModelPortalProposalRequestLite } from "../../api";
import { Result, proposalRequestState, proposalState } from "../../atoms";
import { Order } from "../../types";
import SimpleSpinner from "../SimpleSpinner";
import styles from "./ContactForm.module.less";

const SubmitButton = ({
  order,
  onReady,
  disabled,
}: {
  order: Order;
  onReady: (r: Result) => void;
  disabled: boolean;
}) => {
  const [_, setProposal] = useRecoilState(proposalState);
  const result = useRecoilValue(proposalRequestState);
  useEffect(() => {
    if (!disabled && result) {
      onReady(result);
      setProposal(null);
    }
  }, [disabled, onReady, result, setProposal]);

  return (
    <Button
      mode="tertiary"
      htmlType="submit"
      disabled={
        disabled ||
        order.subOrders.filter((s) => s.budget && s.budget > 0).length === 0
      }
      id="requestProposal"
    >
      Vraag aan
    </Button>
  );
};

export type ContactFormState = "initial" | "success" | "error";

const ContactForm = ({ order }: { order: Order }): JSX.Element => {
  const [form] = AntForm.useForm<SterWebsiteModelPortalProposalRequestLite>();
  const [proposal, setProposal] = useRecoilState(proposalState);
  const handleFormSubmit = useCallback(
    (values: Store) => {
      const proposalData: SterWebsiteModelPortalProposalRequestLite = {
        medium: order.medium,
        userEmail: values.email,
        userFullName: values.name,
        userCompany: values.company,
        subOrders: [
          ...order.subOrders.map(({ period, calculated, ...rest }) => ({
            ...rest,
            from: period[0],
            to: period[1],
            grp: calculated?.grp,
            spotsPerDayChannel: calculated?.spotsPerDayChannel,
            spotsTotal: calculated?.spots,
          })),
        ],
        forecastConversionGroups: order.forecastConversionGroups,
      };

      setProposal(proposalData);
    },
    [
      order.forecastConversionGroups,
      order.medium,
      order.subOrders,
      setProposal,
    ],
  );

  const [formState, setFormState] = useState<ContactFormState>("initial");
  const handleReady = useCallback((r: Result) => {
    switch (r) {
      case "success":
        setFormState("success");
        break;
      case "error":
        setFormState("error");
        break;
      default:
        setFormState("initial");
        break;
    }
  }, []);

  const handleValuesChange = useCallback(() => {
    setFormState("initial");
  }, []);

  return (
    <Container className={styles.contactForm}>
      <Form
        form={form}
        initialValues={proposal ?? {}}
        layout="horizontal"
        onFinish={handleFormSubmit}
        onValuesChange={handleValuesChange}
      >
        <Typography.Title level={2}>
          Ontvang je campagne in je mailbox
        </Typography.Title>
        <Form.Item
          label="Naam"
          name="name"
          rules={[
            {
              required: true,
              message: "Vul je naam in",
            },
          ]}
        >
          <Input className={styles.contactInput} />
        </Form.Item>
        <Form.Item
          label="Bedrijfsnaam"
          name="company"
          rules={[
            {
              required: true,
              message: "Vul je bedrijfsnaam in",
            },
          ]}
        >
          <Input className={styles.contactInput} />
        </Form.Item>
        <Form.Item
          label="E-mailadres"
          name="email"
          rules={[
            {
              required: true,
              message: "Vul je e-mailadres in",
              type: "email",
            },
          ]}
        >
          <Input className={styles.contactInput} />
        </Form.Item>
        {formState !== "initial" && (
          <Alert
            showIcon
            type={formState}
            message=""
            description={
              formState === "success"
                ? "Bedankt voor je aanvraag, je ontvangt je campagne binnen enkele momenten in je mailbox."
                : "Er is iets mis gegaan met je aanvraag, probeer het opnieuw of neem contact op met Ster."
            }
          />
        )}
        <Row justify="end">
          <Suspense fallback={<SimpleSpinner />}>
            <Form.Item shouldUpdate noStyle>
              {() => (
                <SubmitButton
                  order={order}
                  onReady={handleReady}
                  disabled={
                    formState === "success" ||
                    !form.isFieldsTouched(true) ||
                    !!form
                      .getFieldsError()
                      .filter(({ errors }) => errors.length).length
                  }
                />
              )}
            </Form.Item>
          </Suspense>
        </Row>
      </Form>
    </Container>
  );
};

export default memo(ContactForm);
