import { Alert, Button } from "antd";
import { useCallback } from "react";

const ErrorFallback = ({ error }: { error: Error }): JSX.Element => {
  const handleRefresh = useCallback(() => {
    window.location.reload();
  }, []);
  return (
    <Alert
      message="Error"
      description={error.message}
      type="error"
      showIcon
      action={
        <Button size="small" type="text" onClick={handleRefresh}>
          Vernieuwen
        </Button>
      }
    />
  );
};

export default ErrorFallback;
