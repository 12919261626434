import { Icons } from "@ster/ster-toolkit";
import { Fragment } from "react";

const getChannelImage = (channelName: string): JSX.Element => {
  switch (channelName) {
    case "R1":
      return <Icons.NPO1RadioIcon key={channelName} />;
    case "R2":
      return <Icons.NPO2RadioIcon key={channelName} />;
    case "R3":
      return <Icons.NPO3RadioIcon key={channelName} />;
    case "R4":
      return <Icons.NPOKlassiekRadioIcon key={channelName} />;
    case "R5":
      return <Icons.NPO5RadioIcon key={channelName} />;
    case "FX":
      return <Icons.FunXIcon key={channelName} />;
    case "RR":
      return <Icons.RegioIcon key={channelName} />;
    default:
      return <Fragment key={channelName} />;
  }
};

export default getChannelImage;
