import { memo, useMemo } from "react";
import { useRecoilValue } from "recoil";

import { SterWebsiteModelPortalSubOrderLite } from "../../api";
import { forecastsState } from "../../atoms";
import { Medium } from "../../types";
import styles from "./Forecast.module.less";
import ForecastRow from "./ForecastRow";

export interface ForecastRowsProps {
  medium: Medium;
  subOrders: SterWebsiteModelPortalSubOrderLite[];
  conversionGroups: string[];
  onDeleteConversionGroup: (value: string) => void;
}

const ForecastRows = ({
  medium,
  subOrders,
  conversionGroups,
  onDeleteConversionGroup,
}: ForecastRowsProps) => {
  const forecasts = useRecoilValue(
    forecastsState({
      medium,
      forecastRequest: {
        subOrders,
        conversionGroups,
      },
    }),
  );

  const showNoForecastAvailableMessage = useMemo(
    () =>
      !forecasts ||
      forecasts.forecasts?.length === 0 ||
      forecasts.state === "error",
    [forecasts],
  );

  return (
    <>
      {showNoForecastAvailableMessage && (
        <div className={styles.forecastRow}>Nog geen prognose beschikbaar</div>
      )}
      {forecasts?.forecasts?.map((forecast) => (
        <ForecastRow key={JSON.stringify(forecast)} forecast={forecast} />
      ))}
      {forecasts?.conversionForecasts?.map((forecast) => (
        <ForecastRow
          key={JSON.stringify(forecast)}
          forecast={forecast}
          onDeleteConversionGroup={onDeleteConversionGroup}
          canDelete
        />
      ))}
      {forecasts?.regioChannelForecastNotAvailable && (
        <div className={styles.forecastRow}>
          Deze prognose gaat uit van het bereik exclusief het bereik op de
          regionale omroepen. Zodra er voldoende data beschikbaar is zal het
          bereik van de regionale omroepen toegevoegd worden.
        </div>
      )}
      {forecasts?.regioPackageForecastNotAvailable && (
        <div className={styles.forecastRow}>
          Op dit moment is het nog niet mogelijk om een prognose te tonen voor
          het Regio pakket. Zodra er voldoende data beschikbaar is zal de
          prognose worden toegevoegd.
        </div>
      )}
    </>
  );
};

export default memo(ForecastRows);
