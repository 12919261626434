import { Form, Input } from "@ster/ster-toolkit";
import { Col, Row } from "antd";
import classNames from "classnames";
import { Suspense, memo } from "react";

import { Medium, SubOrder } from "../../types";
import SimpleSpinner from "../SimpleSpinner";
import styles from "./BudgetGrpsInput.module.less";
import GrpsInput from "./GrpsInput";

const BudgetGrpsInput = ({
  medium,
  subOrder,
}: {
  medium: Medium;
  subOrder: SubOrder;
}): JSX.Element => (
  <Form.Item label="Budget">
    <Row justify="center" align="middle">
      <Col flex={1}>
        <Form.Item
          noStyle
          name="budget"
          rules={[{ required: true, message: "Budget is verplicht" }]}
        >
          <Input.Currency
            className={classNames("ant-input input", styles.budgetInput)}
            autoComplete="off"
          />
        </Form.Item>
      </Col>
      <Col flex={4} className={styles.grps}>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldsValue }) => {
            const { period } = getFieldsValue(["period"]);

            if (!period || period.length !== 2) {
              // ongeldige periode
              return null;
            }

            return (
              <Suspense fallback={<SimpleSpinner />}>
                <Form.Item name="calculated" noStyle>
                  <GrpsInput
                    medium={medium}
                    calculateInput={{
                      ...subOrder,
                      dateFrom: subOrder.period[0],
                      dateTo: subOrder.period[1],
                    }}
                  />
                </Form.Item>
              </Suspense>
            );
          }}
        </Form.Item>
      </Col>
    </Row>
  </Form.Item>
);

export default memo(BudgetGrpsInput);
