import { Icons, Option, Select, Tooltip } from "@ster/ster-toolkit";
import { SelectValue } from "antd/lib/select";
import { memo, useCallback, useMemo } from "react";
import { useRecoilValue } from "recoil";

import { SterWebsiteModelPortalPackageExtended } from "../api";
import { packagesState } from "../atoms";
import { Medium, PackageData } from "../types";
import { packagesSortOrder } from "../utils";
import styles from "./PackageSelect.module.less";

const PackageSelect = ({
  medium,
  period,
  onChange,
  selected,
}: {
  medium: Medium;
  period: [Date, Date];
  onChange?: (v: PackageData) => void;
  selected?: SterWebsiteModelPortalPackageExtended;
}) => {
  const { packages } = useRecoilValue(
    packagesState({
      medium,
      monthNumber: period[0].getMonth() + 1,
      year: period[0].getFullYear(),
    }),
  );

  const packagesSorted = useMemo(
    () =>
      packages
        .slice()
        .sort(
          (a, b) =>
            packagesSortOrder.indexOf(a.name) -
            packagesSortOrder.indexOf(b.name),
        ),
    [packages],
  );

  const handleChange = useCallback(
    (packageId: SelectValue) => {
      const newSelected = packages.find(({ id }) => id === packageId);
      if (!newSelected) {
        onChange?.({
          packageName: undefined,
          packageCode: undefined,
          targetGroupIndex: undefined,
          targetGroupId: undefined,
          channels: undefined,
          packageId: undefined,
        });
        return;
      }
      onChange?.({
        packageName: newSelected.name,
        packageCode: newSelected.targetGroups[0].packageCode,
        targetGroupIndex: newSelected.targetGroups[0].index,
        targetGroupId: newSelected.targetGroups[0].targetGroupId,
        channels: newSelected.targetGroups[0].channels,
      });
    },
    [onChange, packages],
  );

  return (
    <Select
      value={selected?.id}
      onChange={handleChange}
      placeholder="Selecteer een pakket"
      id="package"
    >
      {packagesSorted.map((p) =>
        p.name.toLowerCase() === "algemeen nut" ? (
          <Option key={p.id} value={p.id}>
            {p.name}
            <Tooltip
              title={
                <>
                  Enkel voor charitatieve organisaties met een keurmerk van het
                  Centraal Bureau Fondsenwerving (CBF) of de status van algemeen
                  nut beogende instelling (ANBI-status)
                </>
              }
              color="#fff"
              overlayClassName={styles.tooltip}
            >
              <span className="help-text">
                <Icons.QuestionIcon width={16} height={16} />
              </span>
            </Tooltip>
          </Option>
        ) : (
          <Option key={p.id} value={p.id}>
            {p.name}
          </Option>
        ),
      )}
    </Select>
  );
};

export default memo(PackageSelect);
