import { atom, selector, selectorFamily } from "recoil";

import { endOfDay, parse, startOfDay } from "date-fns";
import {
  SterWebsiteModelPortalForecastResult,
  SterWebsiteModelPortalProposalRequestLite,
} from "./api";
import {
  fetchCalculate,
  fetchForecastConversionGroups,
  fetchForecasts,
  fetchPackages,
  fetchProposal,
  fetchSalesPeriod,
  fetchSpotLengths,
} from "./apiWrappers";
import { AppSettings, Medium, Order } from "./types";
import { getQuery } from "./utils";

export type Result = "success" | "error";
export interface ForecastResult extends SterWebsiteModelPortalForecastResult {
  state?: Result;
}

export const appSettingsState = atom<AppSettings>({
  key: "appSettings",
  default: {
    homeUrl: window.location.pathname ?? "/portalLite",
    redirectTo: window.location.pathname ?? "/portalLite",
    text: "",
    buttonText: "Bereken",
    buttonStyle: "tertiary",
  },
});

export const queryState = atom({ key: "query", default: getQuery() });

export const orderState = atom({
  key: "order",
  default: selector({
    key: "initialData",
    get: ({ get }) => {
      // Als er geen redirect url is wordt er ook geen order gevuld
      const appSettings = get(appSettingsState);
      if (!appSettings.homeUrl) {
        return null;
      }

      const query = get(queryState);
      const medium = query.get("medium") as Medium;
      const fromString = query.get("from");
      const from = fromString
        ? startOfDay(parse(fromString, "yyyy-MM-dd", new Date()))
        : undefined;
      const toString = query.get("to");
      const to = toString
        ? endOfDay(parse(toString, "yyyy-MM-dd", new Date()))
        : undefined;
      const budget = Number(query.get("budget"));
      const packageCode = query.get("packageCode");
      const targetGroupIndex = query.get("targetGroupIndex");

      if (
        !medium ||
        !from ||
        !to ||
        !budget ||
        !packageCode ||
        !targetGroupIndex
      ) {
        return null;
      }

      const packageRequest = {
        medium,
        monthNumber: from.getMonth() + 1,
        year: from.getFullYear(),
      };
      const packages = get(packagesState(packageRequest));
      const targetGroup = packages.packages
        .find((s) => s.targetGroups.find((t) => t.packageCode === packageCode))
        ?.targetGroups.find((t) => t.index === Number(targetGroupIndex));

      return {
        medium,
        period: [from, to],
        subOrders: [
          {
            id: 1,
            period: [from, to],
            budget,
            packageCode,
            targetGroupId: targetGroup?.targetGroupId ?? "",
            targetGroupIndex: Number(targetGroupIndex),
            spotLength: medium === "tv" ? 30 : 20,
            channels: targetGroup?.channels ?? [],
          },
        ],
        forecastConversionGroups: [],
      } as Order;
    },
  }),
});

export const packagesState = selectorFamily({
  key: "packages",
  get: (request: { medium: Medium; monthNumber: number; year: number }) => () =>
    fetchPackages(request),
});

/**
 * ID of the last added suborder
 */
export const lastIdState = atom({
  key: "lastId",
  default: 1,
});

export const spotLengthsState = selectorFamily({
  key: "spotLengths",
  get: (request: { medium: Medium; year: number }) => () =>
    fetchSpotLengths(request),
});

export const calculateState = selectorFamily({
  key: "calculate",
  get:
    (input: {
      medium: Medium;
      calculateInput: {
        dateFrom?: Date;
        dateTo?: Date;
        packageCode?: string;
        targetGroupId?: string;
        spotLength?: number;
        budget?: number;
        channels?: Array<string>;
        calculated?: never;
      };
    }) =>
    () =>
      fetchCalculate(input),
});

const emptyForcasts: ForecastResult = {};
export const forecastsState = selectorFamily({
  key: "forecasts",
  get:
    (input: {
      medium: Medium;
      forecastRequest: {
        subOrders: {
          id?: number;
          packageCode?: string;
          grp?: number;
          budget?: number;
          targetGroupId?: string;
          from?: Date;
          to?: Date;
          spotLength?: number;
          spotsPerDayChannel?: number;
        }[];
        conversionGroups: string[];
      };
    }) =>
    () => {
      if (
        !input.forecastRequest.subOrders ||
        input.forecastRequest.subOrders.length === 0
      ) {
        return emptyForcasts;
      }
      return fetchForecasts(input)
        .then((result) => ({ ...result, state: "success" }) as ForecastResult)
        .catch(() => ({ state: "error" }) as ForecastResult);
    },
});

export const proposalState =
  atom<SterWebsiteModelPortalProposalRequestLite | null>({
    key: "proposal",
    default: null,
  });

export const proposalRequestState = selector({
  key: "proposalRequest",
  get: ({ get }): Promise<Result | undefined> => {
    const input = get(proposalState);
    if (!input) {
      return Promise.resolve(undefined);
    }

    return fetchProposal({
      proposalRequest: input as SterWebsiteModelPortalProposalRequestLite,
    })
      .then(() => "success" as Result)
      .catch(() => "error" as Result);
  },
});

export const forecastConversionGroupsState = selectorFamily({
  key: "forecastConversionGroups",
  get: (medium: Medium) => () => fetchForecastConversionGroups(medium),
});

export const salesPeriodsState = selector({
  key: "salesPeriods",
  get: fetchSalesPeriod,
});
