import { Layout } from "antd";
import { Suspense, memo, useEffect } from "react";
import { useSetRecoilState } from "recoil";

import { appSettingsState } from "./atoms";
import CreateOrder from "./components/CreateOrder";
import SimpleSpinner from "./components/SimpleSpinner";
import { AppSettings } from "./types";

export interface AppProps {
  settings?: AppSettings;
}

const App = ({ settings }: AppProps): JSX.Element => {
  const setAppSettings = useSetRecoilState(appSettingsState);
  useEffect(() => {
    if (settings) {
      setAppSettings(settings);
    }
  }, [setAppSettings, settings]);

  return (
    <Suspense fallback={<SimpleSpinner />}>
      <Layout>
        <div className="app-container">
          <CreateOrder />
        </div>
      </Layout>
    </Suspense>
  );
};

export default memo(App);
