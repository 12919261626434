import { memo } from "react";
import { useRecoilValue } from "recoil";

import { orderState } from "../atoms";
import OrderForm from "./details/OrderForm";
import CollectInitialData from "./initial/CollectInitialData";

const CreateOrder = (): JSX.Element | null => {
  const order = useRecoilValue(orderState);
  return order !== null ? <OrderForm order={order} /> : <CollectInitialData />;
};

export default memo(CreateOrder);
