/* tslint:disable */
/* eslint-disable */
/**
 * Ster.nl API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SterWebsiteModelPortalCalculationResult
 */
export interface SterWebsiteModelPortalCalculationResult {
  /**
   *
   * @type {number}
   * @memberof SterWebsiteModelPortalCalculationResult
   */
  grp: number;
  /**
   *
   * @type {number}
   * @memberof SterWebsiteModelPortalCalculationResult
   */
  budget: number;
  /**
   *
   * @type {number}
   * @memberof SterWebsiteModelPortalCalculationResult
   */
  spotsPerDayChannel: number;
  /**
   *
   * @type {number}
   * @memberof SterWebsiteModelPortalCalculationResult
   */
  spots?: number;
}

export function SterWebsiteModelPortalCalculationResultFromJSON(
  json: any,
): SterWebsiteModelPortalCalculationResult {
  return SterWebsiteModelPortalCalculationResultFromJSONTyped(json, false);
}

export function SterWebsiteModelPortalCalculationResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SterWebsiteModelPortalCalculationResult {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    grp: json["grp"],
    budget: json["budget"],
    spotsPerDayChannel: json["spotsPerDayChannel"],
    spots: !exists(json, "spots") ? undefined : json["spots"],
  };
}

export function SterWebsiteModelPortalCalculationResultToJSON(
  value?: SterWebsiteModelPortalCalculationResult | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    grp: value.grp,
    budget: value.budget,
    spotsPerDayChannel: value.spotsPerDayChannel,
    spots: value.spots,
  };
}
