import "./index.less";

import { ConfigProvider, ThemeConfig } from "antd";
import nl from "antd/lib/locale/nl_NL";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { RecoilRoot } from "recoil";

import { SterThemeConfig } from "@ster/ster-toolkit";
import App from "./App";
import ErrorFallback from "./components/ErrorFallback";
import reportWebVitals from "./reportWebVitals";
import { AppSettings } from "./types";

const element = document.getElementById("portal-lite") as HTMLElement;
const settings = element.dataset.portalLiteSettings
  ? (JSON.parse(element.dataset.portalLiteSettings) as AppSettings)
  : undefined;

const theme: ThemeConfig = {
  ...SterThemeConfig,
  components: {
    ...SterThemeConfig.components,
    Layout: { bodyBg: "transparant" },
  },
};

createRoot(element).render(
  <StrictMode>
    <RecoilRoot>
      <ConfigProvider locale={nl} theme={theme}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <App settings={settings} />
        </ErrorBoundary>
      </ConfigProvider>
    </RecoilRoot>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
