import { formatNumber } from "@ster/ster-toolkit";
import flatifyObject from "flatify-obj";

import { format } from "date-fns";
import { Order } from "./types";

declare global {
  interface ChatwootApi {
    hasLoaded: boolean;
    setCustomAttributes: (
      input: Record<string, ChatwootType>,
    ) => Promise<unknown>;
  }

  interface Window {
    $chatwoot?: ChatwootApi;
  }
}

export type ChatwootType = string | number | Date | boolean | undefined;

export const flattenOrder = (order: Order): Record<string, ChatwootType> => {
  const converted = {
    Aanvraag: {
      Medium: order.medium as string,
      Periode:
        order.period && order.period[0] && order.period[1]
          ? `${format(order.period[0], "PPP")} t/m ${format(
              order.period[1],
              "PPP",
            )}`
          : undefined,
      "Prognose doelgroepen": order.forecastConversionGroups,
      Url: window.location.href,
      Deelorders: order.subOrders.map((s) => ({
        Naam: `${s.packageCode} (${s.targetGroupId}) - ${s.spotLength}s`,
        Periode:
          s.period && s.period[0] && s.period[1]
            ? `${format(s.period[0], "PPP")} t/m ${format(s.period[1], "PPP")}`
            : undefined,
        Budget: `€ ${formatNumber(s.calculated?.budget ?? 0, 2)}`,
        Grp: `${formatNumber(s.calculated?.grp ?? 0, 1)} GRP's`,
        "Spots per dag per zender":
          s.calculated?.spotsPerDayChannel &&
          s.calculated?.spotsPerDayChannel > 0
            ? formatNumber(s.calculated.spotsPerDayChannel, 1)
            : "n.v.t.",
      })),
    },
  };

  return flatifyObject(converted) as unknown as Record<string, ChatwootType>;
};
