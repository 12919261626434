/* tslint:disable */
/* eslint-disable */
/**
 * Ster.nl API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SterWebsiteModelPortalTargetGroupOutput
 */
export interface SterWebsiteModelPortalTargetGroupOutput {
  /**
   *
   * @type {number}
   * @memberof SterWebsiteModelPortalTargetGroupOutput
   */
  index: number;
  /**
   *
   * @type {string}
   * @memberof SterWebsiteModelPortalTargetGroupOutput
   */
  packageCode: string;
  /**
   *
   * @type {Array<string>}
   * @memberof SterWebsiteModelPortalTargetGroupOutput
   */
  channels: Array<string>;
  /**
   *
   * @type {string}
   * @memberof SterWebsiteModelPortalTargetGroupOutput
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof SterWebsiteModelPortalTargetGroupOutput
   */
  targetGroupId: string;
}

export function SterWebsiteModelPortalTargetGroupOutputFromJSON(
  json: any,
): SterWebsiteModelPortalTargetGroupOutput {
  return SterWebsiteModelPortalTargetGroupOutputFromJSONTyped(json, false);
}

export function SterWebsiteModelPortalTargetGroupOutputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SterWebsiteModelPortalTargetGroupOutput {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    index: json["index"],
    packageCode: json["packageCode"],
    channels: json["channels"],
    name: json["name"],
    targetGroupId: json["targetGroupId"],
  };
}

export function SterWebsiteModelPortalTargetGroupOutputToJSON(
  value?: SterWebsiteModelPortalTargetGroupOutput | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    index: value.index,
    packageCode: value.packageCode,
    channels: value.channels,
    name: value.name,
    targetGroupId: value.targetGroupId,
  };
}
