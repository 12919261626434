/* tslint:disable */
/* eslint-disable */
/**
 * Ster.nl API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SterWebsiteModelPortalSubOrderLite
 */
export interface SterWebsiteModelPortalSubOrderLite {
  /**
   *
   * @type {number}
   * @memberof SterWebsiteModelPortalSubOrderLite
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof SterWebsiteModelPortalSubOrderLite
   */
  packageCode?: string;
  /**
   *
   * @type {number}
   * @memberof SterWebsiteModelPortalSubOrderLite
   */
  grp?: number;
  /**
   *
   * @type {number}
   * @memberof SterWebsiteModelPortalSubOrderLite
   */
  budget?: number;
  /**
   *
   * @type {number}
   * @memberof SterWebsiteModelPortalSubOrderLite
   */
  targetGroupIndex?: number;
  /**
   *
   * @type {Date}
   * @memberof SterWebsiteModelPortalSubOrderLite
   */
  from?: Date;
  /**
   *
   * @type {Date}
   * @memberof SterWebsiteModelPortalSubOrderLite
   */
  to?: Date;
  /**
   *
   * @type {number}
   * @memberof SterWebsiteModelPortalSubOrderLite
   */
  spotLength?: number;
  /**
   *
   * @type {number}
   * @memberof SterWebsiteModelPortalSubOrderLite
   */
  spotsPerDayChannel?: number;
  /**
   *
   * @type {number}
   * @memberof SterWebsiteModelPortalSubOrderLite
   */
  spotsTotal?: number;
}

export function SterWebsiteModelPortalSubOrderLiteFromJSON(
  json: any,
): SterWebsiteModelPortalSubOrderLite {
  return SterWebsiteModelPortalSubOrderLiteFromJSONTyped(json, false);
}

export function SterWebsiteModelPortalSubOrderLiteFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SterWebsiteModelPortalSubOrderLite {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, "id") ? undefined : json["id"],
    packageCode: !exists(json, "packageCode") ? undefined : json["packageCode"],
    grp: !exists(json, "grp") ? undefined : json["grp"],
    budget: !exists(json, "budget") ? undefined : json["budget"],
    targetGroupIndex: !exists(json, "targetGroupIndex")
      ? undefined
      : json["targetGroupIndex"],
    from: !exists(json, "from") ? undefined : new Date(json["from"]),
    to: !exists(json, "to") ? undefined : new Date(json["to"]),
    spotLength: !exists(json, "spotLength") ? undefined : json["spotLength"],
    spotsPerDayChannel: !exists(json, "spotsPerDayChannel")
      ? undefined
      : json["spotsPerDayChannel"],
    spotsTotal: !exists(json, "spotsTotal") ? undefined : json["spotsTotal"],
  };
}

export function SterWebsiteModelPortalSubOrderLiteToJSON(
  value?: SterWebsiteModelPortalSubOrderLite | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    packageCode: value.packageCode,
    grp: value.grp,
    budget: value.budget,
    targetGroupIndex: value.targetGroupIndex,
    from: value.from === undefined ? undefined : value.from.toISOString(),
    to: value.to === undefined ? undefined : value.to.toISOString(),
    spotLength: value.spotLength,
    spotsPerDayChannel: value.spotsPerDayChannel,
    spotsTotal: value.spotsTotal,
  };
}
