/* tslint:disable */
/* eslint-disable */
/**
 * Ster.nl API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  SterWebsiteModelPortalPackageExtended,
  SterWebsiteModelPortalPackageExtendedFromJSON,
  SterWebsiteModelPortalPackageExtendedFromJSONTyped,
  SterWebsiteModelPortalPackageExtendedToJSON,
  SterWebsiteModelPortalPackageSimpleGroup,
  SterWebsiteModelPortalPackageSimpleGroupFromJSON,
  SterWebsiteModelPortalPackageSimpleGroupFromJSONTyped,
  SterWebsiteModelPortalPackageSimpleGroupToJSON,
} from "./";

/**
 *
 * @export
 * @interface SterWebsiteModelPortalPackageOutput
 */
export interface SterWebsiteModelPortalPackageOutput {
  /**
   *
   * @type {Array<SterWebsiteModelPortalPackageSimpleGroup>}
   * @memberof SterWebsiteModelPortalPackageOutput
   */
  simpleGroup: Array<SterWebsiteModelPortalPackageSimpleGroup>;
  /**
   *
   * @type {Array<SterWebsiteModelPortalPackageExtended>}
   * @memberof SterWebsiteModelPortalPackageOutput
   */
  packages: Array<SterWebsiteModelPortalPackageExtended>;
}

export function SterWebsiteModelPortalPackageOutputFromJSON(
  json: any,
): SterWebsiteModelPortalPackageOutput {
  return SterWebsiteModelPortalPackageOutputFromJSONTyped(json, false);
}

export function SterWebsiteModelPortalPackageOutputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SterWebsiteModelPortalPackageOutput {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    simpleGroup: (json["simpleGroup"] as Array<any>).map(
      SterWebsiteModelPortalPackageSimpleGroupFromJSON,
    ),
    packages: (json["packages"] as Array<any>).map(
      SterWebsiteModelPortalPackageExtendedFromJSON,
    ),
  };
}

export function SterWebsiteModelPortalPackageOutputToJSON(
  value?: SterWebsiteModelPortalPackageOutput | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    simpleGroup: (value.simpleGroup as Array<any>).map(
      SterWebsiteModelPortalPackageSimpleGroupToJSON,
    ),
    packages: (value.packages as Array<any>).map(
      SterWebsiteModelPortalPackageExtendedToJSON,
    ),
  };
}
