/* tslint:disable */
/* eslint-disable */
/**
 * Ster.nl API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  SterWebsiteModelPortalForecastTargetGroup,
  SterWebsiteModelPortalForecastTargetGroupFromJSON,
  SterWebsiteModelPortalForecastTargetGroupFromJSONTyped,
  SterWebsiteModelPortalForecastTargetGroupToJSON,
} from "./";

/**
 *
 * @export
 * @interface SterWebsiteModelPortalForecastConversionGroups
 */
export interface SterWebsiteModelPortalForecastConversionGroups {
  /**
   *
   * @type {{ [key: string]: Array<SterWebsiteModelPortalForecastTargetGroup>; }}
   * @memberof SterWebsiteModelPortalForecastConversionGroups
   */
  conversionGroups: {
    [key: string]: Array<SterWebsiteModelPortalForecastTargetGroup>;
  };
}

export function SterWebsiteModelPortalForecastConversionGroupsFromJSON(
  json: any,
): SterWebsiteModelPortalForecastConversionGroups {
  return SterWebsiteModelPortalForecastConversionGroupsFromJSONTyped(
    json,
    false,
  );
}

export function SterWebsiteModelPortalForecastConversionGroupsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SterWebsiteModelPortalForecastConversionGroups {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    conversionGroups: json["conversionGroups"],
  };
}

export function SterWebsiteModelPortalForecastConversionGroupsToJSON(
  value?: SterWebsiteModelPortalForecastConversionGroups | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    conversionGroups: value.conversionGroups,
  };
}
