/* tslint:disable */
/* eslint-disable */
/**
 * Ster.nl API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  SterWebsiteModelPortalSalesPeriod,
  SterWebsiteModelPortalSalesPeriodFromJSON,
  SterWebsiteModelPortalSalesPeriodFromJSONTyped,
  SterWebsiteModelPortalSalesPeriodToJSON,
} from "./";

/**
 *
 * @export
 * @interface SterWebsiteModelPortalSalesPeriodResult
 */
export interface SterWebsiteModelPortalSalesPeriodResult {
  /**
   *
   * @type {string}
   * @memberof SterWebsiteModelPortalSalesPeriodResult
   */
  medium?: string;
  /**
   *
   * @type {Array<SterWebsiteModelPortalSalesPeriod>}
   * @memberof SterWebsiteModelPortalSalesPeriodResult
   */
  result?: Array<SterWebsiteModelPortalSalesPeriod>;
}

export function SterWebsiteModelPortalSalesPeriodResultFromJSON(
  json: any,
): SterWebsiteModelPortalSalesPeriodResult {
  return SterWebsiteModelPortalSalesPeriodResultFromJSONTyped(json, false);
}

export function SterWebsiteModelPortalSalesPeriodResultFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SterWebsiteModelPortalSalesPeriodResult {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    medium: !exists(json, "medium") ? undefined : json["medium"],
    result: !exists(json, "result")
      ? undefined
      : (json["result"] as Array<any>).map(
          SterWebsiteModelPortalSalesPeriodFromJSON,
        ),
  };
}

export function SterWebsiteModelPortalSalesPeriodResultToJSON(
  value?: SterWebsiteModelPortalSalesPeriodResult | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    medium: value.medium,
    result:
      value.result === undefined
        ? undefined
        : (value.result as Array<any>).map(
            SterWebsiteModelPortalSalesPeriodToJSON,
          ),
  };
}
