import { Alert } from "antd";
import { memo, useMemo } from "react";
import { Medium } from "../../types";
import { isSameOrAfterDay, isSameOrBeforeDay } from "../../utils";
import styles from "./Top2000Warning.module.less";

export interface Top2000WarningProps {
  from: Date;
  to: Date;
  medium: Medium;
  channels?: string[] | null;
}

const Top2000Warning = memo(
  ({ medium, from, to, channels }: Top2000WarningProps) => {
    const isTop2000Period = useMemo(() => {
      const currentYear = from.getFullYear();
      return (
        isSameOrAfterDay(to, new Date(`${currentYear}-12-25`)) &&
        isSameOrBeforeDay(from, new Date(`${currentYear}-12-31`))
      );
    }, [from, to]);

    const isSeriousRequestPeriod = useMemo(() => {
      const currentYear = from.getFullYear();
      return (
        isSameOrAfterDay(to, new Date(`${currentYear}-12-18`)) &&
        isSameOrBeforeDay(from, new Date(`${currentYear}-12-24`))
      );
    }, [from, to]);

    if (
      // Als het medium niet radio is hoeft er geen waarschuwing getoond te worden
      medium !== "radio"
    ) {
      return undefined;
    }

    // Top 2000 waarschuwing voor alle pakketten met radio 2
    const showTop2000Warning =
      isTop2000Period && (!channels || channels.includes("R2"));

    // Serious Request waarschuwing voor alle pakketten met 3FM, muv Your Time
    const showSeriousRequestWarning =
      isSeriousRequestPeriod && (!channels || channels.includes("R3"));

    const top2000Warning = (
      <>
        <b>Let op:</b> op NPO Radio 2 hoor je van 25 tot en met 31 december de
        Top 2000. In deze periode gelden de reguliere NPO Radio
        2-inkoopmogelijkheden niet, maar is het wel mogelijk een reclamecampagne
        aan te vragen met de inkoopopties Top 2000 Ster Time (met of zonder
        spreiding) of Top 2000 Your Time.
      </>
    );

    const seriousRequestWarning = (
      <>
        <b>Let op:</b> op NPO 3FM hoor je van 18 tot en met 24 december Serious
        Request. In deze periode gelden de reguliere NPO 3FM-inkoopmogelijkheden
        niet, maar is het wel mogelijk een reclamecampagne aan te vragen met de
        inkoopopties Serious Request (met spreiding) of Your Time.
      </>
    );

    const seriousRequestAndTop2000Warning = (
      <>
        <b>Let op:</b> in de door jou geselecteerde periode hoor je op NPO 3FM
        Serious Request (van 18 tot en met 24 december) en/of op NPO Radio 2 de
        Top 2000 (van 25 tot en met 31 december). Voor deze evenementen gelden
        aangepaste inkoopopties.
      </>
    );

    const getWarningContent = () => {
      if (showSeriousRequestWarning && showTop2000Warning) {
        return {
          text: seriousRequestAndTop2000Warning,
          link: "https://ster.nl/uitgelichte-inkoopopties/",
        };
      }
      if (showTop2000Warning) {
        return {
          text: top2000Warning,
          link: "https://www.ster.nl/uitgelichte-inkoopopties/top-2000-2024/",
        };
      }
      if (showSeriousRequestWarning) {
        return {
          text: seriousRequestWarning,
          link: "https://www.ster.nl/uitgelichte-inkoopopties/serious-request/",
        };
      }
      return undefined;
    };

    if (getWarningContent()) {
      const { text, link } = getWarningContent() ?? {};
      return (
        <Alert
          showIcon
          className={styles.top2000}
          type="warning"
          description={
            <>
              {text}
              <br />
              <>
                Meer weten? Neem dan{" "}
                <a
                  href="https://www.ster.nl/contact/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  contact
                </a>{" "}
                met ons op of ga naar de{" "}
                <a href={link} rel="noopener noreferrer" target="_blank">
                  website
                </a>
                .
              </>
            </>
          }
        />
      );
    }

    return undefined;
  },
);

export default Top2000Warning;
