import {
  Button,
  Form,
  Icons,
  Input,
  Tooltip,
  Typography,
} from "@ster/ster-toolkit";
import { Form as AntForm, Radio, Row } from "antd";
import classNames from "classnames";
// eslint-disable-next-line import/no-extraneous-dependencies
import { FieldData, InternalNamePath } from "rc-field-form/lib/interface";
import { Suspense, memo, useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import { endOfDay, format, startOfDay } from "date-fns";
import { appSettingsState } from "../../atoms";
import { flattenOrder } from "../../chatwoot";
import { InitialData } from "../../types";
import { getInitialValidPeriod } from "../../utils";
import PackageSelect from "../PackageSelect";
import PeriodInput from "../PeriodInput";
import SimpleSpinner from "../SimpleSpinner";
import ChannelSelect from "./ChannelSelect";
import styles from "./CollectInitialData.module.less";

const CollectInitialData = () => {
  const initialDates = getInitialValidPeriod();

  const [busy, setBusy] = useState(false);
  const [form] = AntForm.useForm<InitialData>();
  const { redirectTo, text, buttonText, buttonStyle, defaultMedium } =
    useRecoilValue(appSettingsState);

  const [selectedMedium, setSelectedMedium] = useState<string>();
  const [selectedPackageCode, setSelectedPackageCode] = useState<string>();

  useEffect(() => {
    setSelectedMedium(defaultMedium);
  }, [defaultMedium]);

  useEffect(() => {
    if (selectedPackageCode) {
      form.validateFields(["period"]);
    }
  }, [form, selectedPackageCode]);

  const handleFieldsChange = useCallback(
    (changedFields: FieldData[]) => {
      if (
        changedFields.length === 1 &&
        changedFields.find((f) => {
          const names = f.name as InternalNamePath;
          return names.includes("medium");
        })
      ) {
        setSelectedMedium(changedFields[0].value);
        form.resetFields(["package"]);
      }

      if (
        changedFields.length === 1 &&
        changedFields.find((f) => {
          const names = f.name as InternalNamePath;
          return names.includes("package");
        })
      ) {
        setSelectedPackageCode(changedFields[0].value.packageCode);
      }

      // set chatwoot attributes
      if (window.$chatwoot && window.$chatwoot.hasLoaded) {
        const {
          medium,
          period,
          package: { packageCode, targetGroupIndex } = {},
          budget,
        } = form.getFieldsValue();
        const flattenedOrder = flattenOrder({
          medium,
          period,
          subOrders: [
            {
              id: 1,
              period,
              packageCode,
              targetGroupIndex,
              spotLength: 0,
              calculated: { budget },
            },
          ],
          forecastConversionGroups: [],
        });
        window.$chatwoot?.setCustomAttributes({
          campagne_calculator_aanvraag: JSON.stringify(flattenedOrder),
        });
      }
    },
    [form],
  );

  const handleSubmit = useCallback(
    ({
      medium,
      period,
      package: { packageCode, targetGroupIndex } = {},
      budget,
    }: InitialData) => {
      setBusy(true);
      const from = format(startOfDay(period[0]), "yyyy-MM-dd");
      const to = format(endOfDay(period[1]), "yyyy-MM-dd");

      // redirect
      window.location.assign(
        `${redirectTo}?medium=${encodeURIComponent(
          medium,
        )}&from=${encodeURIComponent(from)}&to=${encodeURIComponent(
          to,
        )}&packageCode=${encodeURIComponent(
          packageCode ?? "",
        )}&targetGroupIndex=${encodeURIComponent(
          targetGroupIndex ?? "",
        )}&budget=${encodeURIComponent(budget ?? "")}`,
      );
    },
    [redirectTo],
  );

  if (!selectedMedium) {
    return null;
  }

  return (
    <Form
      form={form}
      onFieldsChange={handleFieldsChange}
      onFinish={handleSubmit}
      initialValues={{
        medium: selectedMedium,
        period: initialDates,
        package: { packageCode: undefined, targetGroupId: undefined },
        budget: undefined,
      }}
      layout="horizontal"
      className={classNames(styles.form, "fixedWidthLabels", "initial-form")}
    >
      <Form.Item
        name="medium"
        rules={[{ required: true, message: "Kies een medium" }]}
        className={styles.medium}
      >
        <Radio.Group
          options={[
            { label: "Televisie", value: "tv" },
            { label: "Radio", value: "radio" },
          ].sort(
            (a, b) =>
              (a.value === defaultMedium ? 0 : 1) -
              (b.value === defaultMedium ? 0 : 1),
          )}
          optionType="button"
        />
      </Form.Item>
      <Form.Item label="Periode" shouldUpdate>
        {({ getFieldsValue }) => {
          const { medium, period } = getFieldsValue(["medium", "period"]);
          return (
            <Suspense fallback={<SimpleSpinner invertColor />}>
              <PeriodInput
                medium={medium}
                period={period}
                allowClear
                useSalesPeriod
                packageCode={selectedPackageCode}
              />
            </Suspense>
          );
        }}
      </Form.Item>
      <Form.Item
        shouldUpdate
        label={
          selectedMedium === "tv" ? (
            <>
              Pakket
              <Tooltip
                title={
                  <>
                    De getoonde pakketten zijn inclusief spreiding.
                    <table>
                      <tbody>
                        <tr>
                          <td>Primetime</td>
                          <td>18:00 - 00:00 uur</td>
                        </tr>
                        <tr>
                          <td>Stertime</td>
                          <td>Hele dag</td>
                        </tr>
                        <tr>
                          <td>Daytime</td>
                          <td>06:00 - 00:00 uur</td>
                        </tr>
                        <tr>
                          <td>Senioren</td>
                          <td>
                            NPO 1 06:00 - 18:00 uur
                            <br />
                            NPO 2 06:00 - 00:00 uur
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                }
                color="#fff"
                overlayClassName={styles.tooltip}
              >
                <span className="help-text">
                  <Icons.QuestionIcon width={16} height={16} />
                </span>
              </Tooltip>
            </>
          ) : (
            "Zender / Doelgroep"
          )
        }
      >
        {({ getFieldsValue }) => {
          const { medium, period } = getFieldsValue(["medium", "period"]);
          return (
            <Suspense fallback={<SimpleSpinner invertColor />}>
              {
                // eslint-disable-next-line no-nested-ternary
                period && period.length === 2 ? (
                  medium === "tv" ? (
                    <Form.Item
                      name="package"
                      rules={[{ required: true, message: "Kies een pakket" }]}
                      className={styles.packages}
                    >
                      <PackageSelect medium={medium} period={period} />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      name="package"
                      rules={[{ required: true, message: "Kies een zender" }]}
                      className={styles.packages}
                    >
                      <ChannelSelect medium={medium} period={period} />
                    </Form.Item>
                  )
                ) : (
                  <Typography.Text type="secondary">
                    Selecteer eerst een periode
                  </Typography.Text>
                )
              }
            </Suspense>
          );
        }}
      </Form.Item>
      <Form.Item
        label="Budget"
        name="budget"
        rules={[{ required: true, message: "Vul een budget in" }]}
        id="budget"
        className={styles.budget}
      >
        <Input.Currency autoComplete="off" />
      </Form.Item>
      {text && (
        <Row>
          <Typography.Paragraph className={styles.content}>
            {text}
          </Typography.Paragraph>
        </Row>
      )}
      <Row>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldsValue, getFieldsError }) => {
            const {
              package: { packageCode, targetGroupIndex } = {
                packageCode: undefined,
                targetGroupIndex: undefined,
              },
              budget,
            } = getFieldsValue(["budget", "package"]);

            return (
              <Button
                mode={buttonStyle}
                htmlType="submit"
                disabled={
                  busy ||
                  !!getFieldsError().filter(({ errors }) => errors.length)
                    .length ||
                  !packageCode ||
                  !targetGroupIndex ||
                  !budget
                }
                loading={busy}
              >
                {buttonText}
              </Button>
            );
          }}
        </Form.Item>
      </Row>
    </Form>
  );
};

export default memo(CollectInitialData);
