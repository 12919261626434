/* tslint:disable */
/* eslint-disable */
/**
 * Ster.nl API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  SterWebsiteModelPortalSubOrderLite,
  SterWebsiteModelPortalSubOrderLiteFromJSON,
  SterWebsiteModelPortalSubOrderLiteFromJSONTyped,
  SterWebsiteModelPortalSubOrderLiteToJSON,
} from "./";

/**
 *
 * @export
 * @interface SterWebsiteModelPortalForecastRequest
 */
export interface SterWebsiteModelPortalForecastRequest {
  /**
   *
   * @type {Array<SterWebsiteModelPortalSubOrderLite>}
   * @memberof SterWebsiteModelPortalForecastRequest
   */
  subOrders: Array<SterWebsiteModelPortalSubOrderLite>;
  /**
   *
   * @type {Array<string>}
   * @memberof SterWebsiteModelPortalForecastRequest
   */
  conversionGroups?: Array<string>;
}

export function SterWebsiteModelPortalForecastRequestFromJSON(
  json: any,
): SterWebsiteModelPortalForecastRequest {
  return SterWebsiteModelPortalForecastRequestFromJSONTyped(json, false);
}

export function SterWebsiteModelPortalForecastRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SterWebsiteModelPortalForecastRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    subOrders: (json["subOrders"] as Array<any>).map(
      SterWebsiteModelPortalSubOrderLiteFromJSON,
    ),
    conversionGroups: !exists(json, "conversionGroups")
      ? undefined
      : json["conversionGroups"],
  };
}

export function SterWebsiteModelPortalForecastRequestToJSON(
  value?: SterWebsiteModelPortalForecastRequest | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    subOrders: (value.subOrders as Array<any>).map(
      SterWebsiteModelPortalSubOrderLiteToJSON,
    ),
    conversionGroups: value.conversionGroups,
  };
}
