/* tslint:disable */
/* eslint-disable */
/**
 * Ster.nl API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  SterWebsiteModelPortalSubOrderLite,
  SterWebsiteModelPortalSubOrderLiteFromJSON,
  SterWebsiteModelPortalSubOrderLiteFromJSONTyped,
  SterWebsiteModelPortalSubOrderLiteToJSON,
} from "./";

/**
 *
 * @export
 * @interface SterWebsiteModelPortalProposalRequestLite
 */
export interface SterWebsiteModelPortalProposalRequestLite {
  /**
   *
   * @type {string}
   * @memberof SterWebsiteModelPortalProposalRequestLite
   */
  userEmail: string;
  /**
   *
   * @type {string}
   * @memberof SterWebsiteModelPortalProposalRequestLite
   */
  userFullName: string;
  /**
   *
   * @type {string}
   * @memberof SterWebsiteModelPortalProposalRequestLite
   */
  userCompany: string;
  /**
   *
   * @type {string}
   * @memberof SterWebsiteModelPortalProposalRequestLite
   */
  medium: string;
  /**
   *
   * @type {Array<string>}
   * @memberof SterWebsiteModelPortalProposalRequestLite
   */
  forecastConversionGroups?: Array<string>;
  /**
   *
   * @type {Array<SterWebsiteModelPortalSubOrderLite>}
   * @memberof SterWebsiteModelPortalProposalRequestLite
   */
  subOrders: Array<SterWebsiteModelPortalSubOrderLite>;
}

export function SterWebsiteModelPortalProposalRequestLiteFromJSON(
  json: any,
): SterWebsiteModelPortalProposalRequestLite {
  return SterWebsiteModelPortalProposalRequestLiteFromJSONTyped(json, false);
}

export function SterWebsiteModelPortalProposalRequestLiteFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SterWebsiteModelPortalProposalRequestLite {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    userEmail: json["userEmail"],
    userFullName: json["userFullName"],
    userCompany: json["userCompany"],
    medium: json["medium"],
    forecastConversionGroups: !exists(json, "forecastConversionGroups")
      ? undefined
      : json["forecastConversionGroups"],
    subOrders: (json["subOrders"] as Array<any>).map(
      SterWebsiteModelPortalSubOrderLiteFromJSON,
    ),
  };
}

export function SterWebsiteModelPortalProposalRequestLiteToJSON(
  value?: SterWebsiteModelPortalProposalRequestLite | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    userEmail: value.userEmail,
    userFullName: value.userFullName,
    userCompany: value.userCompany,
    medium: value.medium,
    forecastConversionGroups: value.forecastConversionGroups,
    subOrders: (value.subOrders as Array<any>).map(
      SterWebsiteModelPortalSubOrderLiteToJSON,
    ),
  };
}
