/* tslint:disable */
/* eslint-disable */
/**
 * Ster.nl API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SterWebsiteModelPortalCalculateInput
 */
export interface SterWebsiteModelPortalCalculateInput {
  /**
   *
   * @type {number}
   * @memberof SterWebsiteModelPortalCalculateInput
   */
  productId?: number;
  /**
   *
   * @type {Date}
   * @memberof SterWebsiteModelPortalCalculateInput
   */
  dateFrom?: Date;
  /**
   *
   * @type {Date}
   * @memberof SterWebsiteModelPortalCalculateInput
   */
  dateTo?: Date;
  /**
   *
   * @type {string}
   * @memberof SterWebsiteModelPortalCalculateInput
   */
  packageCode?: string;
  /**
   *
   * @type {string}
   * @memberof SterWebsiteModelPortalCalculateInput
   */
  targetGroupId?: string;
  /**
   *
   * @type {number}
   * @memberof SterWebsiteModelPortalCalculateInput
   */
  spotLength?: number;
  /**
   *
   * @type {number}
   * @memberof SterWebsiteModelPortalCalculateInput
   */
  budget?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof SterWebsiteModelPortalCalculateInput
   */
  channels?: Array<string>;
}

export function SterWebsiteModelPortalCalculateInputFromJSON(
  json: any,
): SterWebsiteModelPortalCalculateInput {
  return SterWebsiteModelPortalCalculateInputFromJSONTyped(json, false);
}

export function SterWebsiteModelPortalCalculateInputFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SterWebsiteModelPortalCalculateInput {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    productId: !exists(json, "productId") ? undefined : json["productId"],
    dateFrom: !exists(json, "dateFrom")
      ? undefined
      : new Date(json["dateFrom"]),
    dateTo: !exists(json, "dateTo") ? undefined : new Date(json["dateTo"]),
    packageCode: !exists(json, "packageCode") ? undefined : json["packageCode"],
    targetGroupId: !exists(json, "targetGroupId")
      ? undefined
      : json["targetGroupId"],
    spotLength: !exists(json, "spotLength") ? undefined : json["spotLength"],
    budget: !exists(json, "budget") ? undefined : json["budget"],
    channels: !exists(json, "channels") ? undefined : json["channels"],
  };
}

export function SterWebsiteModelPortalCalculateInputToJSON(
  value?: SterWebsiteModelPortalCalculateInput | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    productId: value.productId,
    dateFrom:
      value.dateFrom === undefined ? undefined : value.dateFrom.toISOString(),
    dateTo: value.dateTo === undefined ? undefined : value.dateTo.toISOString(),
    packageCode: value.packageCode,
    targetGroupId: value.targetGroupId,
    spotLength: value.spotLength,
    budget: value.budget,
    channels: value.channels,
  };
}
