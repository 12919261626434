/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
import { Form, Option, Select } from "@ster/ster-toolkit";
import { Select as SelectAntD } from "antd";
import { SelectValue } from "antd/lib/select";
import { memo, useMemo } from "react";

import { SterWebsiteModelPortalPackageExtended } from "../../api";
import { Medium } from "../../types";
import { getChannelName, getTargetGroupName } from "../../utils";
import getChannelImage from "../channelImages";
import styles from "./SubOrderForm.module.less";

const RadioTargetGroups = memo(
  ({
    selectedPackage,
    handleTargetGroupChange,
    value,
  }: {
    selectedPackage: SterWebsiteModelPortalPackageExtended;
    handleTargetGroupChange: (newValue: SelectValue) => void;
    value?: SelectValue;
  }) => {
    const singleChannels = useMemo(
      () =>
        selectedPackage?.targetGroups.filter((s) => s.channels?.length === 1) ??
        [],
      [selectedPackage?.targetGroups],
    );

    const multiChannels = useMemo(
      () =>
        (
          selectedPackage?.targetGroups.filter((s) => s.channels?.length > 1) ??
          []
        ).sort((a, b) => a.targetGroupId.localeCompare(b.targetGroupId)),
      [selectedPackage?.targetGroups],
    );

    return (
      <Select onChange={handleTargetGroupChange} value={value}>
        <SelectAntD.OptGroup label="Zender">
          {singleChannels?.map(({ channels, index }) => (
            <Option key={index} value={index}>
              <div className="channels">
                {channels.map((c) => getChannelImage(c))}
              </div>
              <div>{getChannelName(channels.join(", "))}</div>
            </Option>
          ))}
        </SelectAntD.OptGroup>
        {multiChannels.length > 0 && (
          <SelectAntD.OptGroup label="Combinatiepakket">
            {multiChannels?.map(({ channels, index, targetGroupId }) => (
              <Option key={index} value={index}>
                <div className="channels">
                  {channels.map((c) => getChannelImage(c))}
                </div>
                {getTargetGroupName(targetGroupId)}
              </Option>
            ))}
          </SelectAntD.OptGroup>
        )}
      </Select>
    );
  },
);

const TVTargetGroups = memo(
  ({
    selectedPackage,
    handleTargetGroupChange,
    value,
  }: {
    selectedPackage: SterWebsiteModelPortalPackageExtended;
    handleTargetGroupChange: (newValue: SelectValue) => void;
    value?: SelectValue;
  }) => (
    <Select onChange={handleTargetGroupChange} value={value}>
      {selectedPackage?.targetGroups?.map((p) => (
        <Option key={p.index} value={p.index}>
          <div className="channels">
            {p.channels.map((c) => getChannelImage(c))}
          </div>
          <div>{p.name}</div>
        </Option>
      ))}
    </Select>
  ),
);

const TargetGroupSelect = memo(
  ({
    selectedPackage,
    handleTargetGroupChange,
    medium,
  }: {
    selectedPackage: SterWebsiteModelPortalPackageExtended;
    handleTargetGroupChange: (value: SelectValue) => void;
    medium: Medium;
  }) => (
    <Form.Item
      label="Doelgroep"
      name="targetGroupIndex"
      required
      className="channelSelect"
    >
      {selectedPackage.targetGroups.length === 1 ? (
        <div className={styles.channelWrapper}>
          {selectedPackage.targetGroups[0].channels.length > 0 ? (
            <div className="channels">
              {selectedPackage.targetGroups[0].channels.map((c) =>
                getChannelImage(c),
              )}
            </div>
          ) : (
            <div>{selectedPackage.targetGroups[0].name}</div>
          )}
        </div>
      ) : medium === "radio" ? (
        <RadioTargetGroups
          handleTargetGroupChange={handleTargetGroupChange}
          selectedPackage={selectedPackage}
        />
      ) : (
        <TVTargetGroups
          handleTargetGroupChange={handleTargetGroupChange}
          selectedPackage={selectedPackage}
        />
      )}
    </Form.Item>
  ),
);

export default TargetGroupSelect;
