/* tslint:disable */
/* eslint-disable */
/**
 * Ster.nl API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  SterWebsiteModelPortalPackageSimple,
  SterWebsiteModelPortalPackageSimpleFromJSON,
  SterWebsiteModelPortalPackageSimpleFromJSONTyped,
  SterWebsiteModelPortalPackageSimpleToJSON,
} from "./";

/**
 *
 * @export
 * @interface SterWebsiteModelPortalPackageSimpleGroup
 */
export interface SterWebsiteModelPortalPackageSimpleGroup {
  /**
   *
   * @type {number}
   * @memberof SterWebsiteModelPortalPackageSimpleGroup
   */
  minDays?: number;
  /**
   *
   * @type {Array<SterWebsiteModelPortalPackageSimple>}
   * @memberof SterWebsiteModelPortalPackageSimpleGroup
   */
  packages: Array<SterWebsiteModelPortalPackageSimple>;
}

export function SterWebsiteModelPortalPackageSimpleGroupFromJSON(
  json: any,
): SterWebsiteModelPortalPackageSimpleGroup {
  return SterWebsiteModelPortalPackageSimpleGroupFromJSONTyped(json, false);
}

export function SterWebsiteModelPortalPackageSimpleGroupFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): SterWebsiteModelPortalPackageSimpleGroup {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    minDays: !exists(json, "minDays") ? undefined : json["minDays"],
    packages: (json["packages"] as Array<any>).map(
      SterWebsiteModelPortalPackageSimpleFromJSON,
    ),
  };
}

export function SterWebsiteModelPortalPackageSimpleGroupToJSON(
  value?: SterWebsiteModelPortalPackageSimpleGroup | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    minDays: value.minDays,
    packages: (value.packages as Array<any>).map(
      SterWebsiteModelPortalPackageSimpleToJSON,
    ),
  };
}
